import React from 'react'
import Fade from 'react-reveal/Fade'
import Bullet from './Bullet'
import Button from './Button'

export default () => (
  <div className="container mx-auto mt-10 sm:mt-20">
    <h2 className="text-center text-3xl lg:text-5xl font-semibold text-black leading-snug tracking-wider">
      Pricing
    </h2>
    <p className="text-center text-lg text-gray-700 mt-2 px-6">
      FinStar is available in different subscription-based plans.
    </p>
    <p className="text-center text-lg text-gray-700 mt-2 px-6">
      The prices are entirely predictable since they are based on the modules used, and there are no
      term licenses. Choose the one better fits your organization.
    </p>
    <div className="h-1 mx-auto bg-indigo-200 w-24 opacity-75 mt-4 rounded" />

    <div className="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
      <div className="relative block flex flex-col md:flex-row items-center">
        <Fade left>
          <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4">
            <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
              <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                  Basic
                </h1>
                <h2 className="text-sm text-gray-600 text-center pb-6">from $?.?? /mo</h2>
                Everything you need to get started. The Basic plan offers you the functionalities to
                start managing your business online.
              </div>

              <div className="flex flex-wrap mt-3 px-6">
                <ul>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">FX Trade Entry</span>
                  </li>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">Market Data</span>
                  </li>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">Basic Reports</span>
                  </li>
                </ul>
              </div>
              <div className="block flex items-center p-8  uppercase">
                <Button className="mt-3 text-lg font-semibold w-full shadow-xl">Select</Button>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg">
            <div className="text-sm leading-none rounded-t-lg bg-primary-darker text-white font-semibold uppercase py-4 text-center tracking-wide">
              Most Popular
            </div>
            <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
              <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                Pro
              </h1>
              <h2 className="text-sm text-gray-600 text-center pb-6">
                from <span className="text-3xl">$??</span> /mo
              </h2>
              All the features that the Basic plan has plus access to additional reports,
              Mark-to-Market valuations and different instruments.
            </div>
            <div className="flex pl-12 justify-start sm:justify-start mt-3">
              <ul>
                <li className="flex items-center">
                  <Bullet />
                  <span className="text-gray-700 text-lg ml-3">Multiple Markets</span>
                </li>
                <li className="flex items-center">
                  <Bullet />
                  <span className="text-gray-700 text-lg ml-3">Market Data</span>
                </li>
                <li className="flex items-center">
                  <Bullet />
                  <span className="text-gray-700 text-lg ml-3">Mark-to-Market Evaluations</span>
                </li>
                <li className="flex items-center">
                  <Bullet />
                  <span className="text-gray-700 text-lg ml-3">FX Exposure Management</span>
                </li>
              </ul>
            </div>

            <div className="block flex items-center p-8  uppercase">
              <Button className="mt-3 text-lg font-semibold w-full shadow-xl">Select</Button>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-ml-4">
            <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
              <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                  Enteprise
                </h1>
                <h2 className="text-sm text-gray-600 text-center pb-6">from $??? /mo</h2>
                All features included in the Pro plan and more.
              </div>
              <div className="flex flex-wrap mt-3 px-6">
                <ul>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">
                      Advanced Financial Risk Management
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">Sensitivity Analysis</span>
                  </li>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">Hedge Effectiveness Testing</span>
                  </li>
                  <li className="flex items-center">
                    <Bullet />
                    <span className="text-gray-700 text-lg ml-3">Counterparty Risk Management</span>
                  </li>
                </ul>
              </div>

              <div className="block flex items-center p-8  uppercase">
                <Button className="mt-3 text-lg font-semibold w-full shadow-xl">Select</Button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  </div>
)
