import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="py-10">
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
      <Fade left>
        <div className="lg:w-1/2">{primarySlot}</div>
      </Fade>
      <Fade right>
        <div
          className={`mt-10 lg:mt-0 w-full lg:w-1/2 ${reverseOrder && `order-last lg:order-first`}`}
        >
          {secondarySlot}
        </div>
      </Fade>
    </div>
  </section>
)

SplitSection.defaultProps = {
  id: '',
  reverseOrder: false,
}

SplitSection.propTypes = {
  id: PropTypes.string,
  primarySlot: PropTypes.element.isRequired,
  secondarySlot: PropTypes.element.isRequired,
  reverseOrder: PropTypes.bool,
}

export default SplitSection
