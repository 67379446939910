import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Card from './Card'

const Feature = ({ title, children }) => (
  <Fade left>
    <div className="w-full md:w-1/2 lg:w-1/3 px-3">
      <Card className="sm:p-8 mb-6 bg-white hover:bg-gray-100">
        <h1 className="leading-tight tracking-tight font-bold text-xl">{title}</h1>
        <p className="mt-4">{children}</p>
      </Card>
    </div>
  </Fade>
)

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default () => (
  <div className="flex flex-wrap flex-col sm:flex-row sm:-mx-3 mt-12">
    <Feature title="Cash & Liquidity Management">
      Get real-time visibility into global cash positions and control liquidity risk across the
      enterprise.
    </Feature>
    <Feature title="Global Cash Management">
      Capture all cash flows in a single. Automate bank account management, cash positioning and
      reconciliation.
    </Feature>
    <Feature title="Cash Forecasting">
      Improve the management of your working capital. Ensure that your business has the necessary
      liquidity to meet its obligations and avoid funding issues.
    </Feature>
    <Feature title="Derivative Valuation">
      Use our integrated market data to get independent and accurate derivative valuations.
    </Feature>
    <Feature title="Financial Risk Management">
      Manage exposures holistically and protect your company from increasing volatility and
      uncertainty.
    </Feature>
    <Feature title="FX Exposure Management">
      Analyze compliance with your internal hedging policies by tracking of currency exposures and
      FX transactions.
    </Feature>
    <Feature title="Sensitivity Analysis">
      Modify currency and interest rate curves to analyze the sensitivity of different FX and
      Interest Rate projections, including yield curve shifts.
    </Feature>
    <Feature title="Hedge Effectiveness Testing">
      Perform Prospective and Retrospective Hedge Effectiveness Testing to comply with Hedge
      Accounting standards.
    </Feature>
    <Feature title="Dashboards & Reporting">
      Have a daily view of cash positions and exposures with our dashboards. Perform analysis and
      create reports automatically to answer tough questions on the fly.
    </Feature>
  </div>
)
