import React from 'react'
import Button from './Button'
import HeroImage2 from '../svg/HeroImage2'

export default () => (
  <section className="text-gray-800">
    <div className="container mx-auto px-8 lg:flex -mt-2 md:pt-4">
      <div className="text-center lg:text-left lg:w-1/2">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none uppercase">
          FinStar
        </h1>
        <h1 className="text-xl lg:text-2xl xl:text-3xl font-bold">
          Cloud-based SaaS Treasury Management System
        </h1>
        <p className="text-xl lg:text-2xl mt-6 font-light">
          A complete cloud solution for your finance and treasury management needs.
        </p>
        <p className="pt-10 mt-8 md:mt-12">
          <Button to="#features" size="lg" className="">
            Get Started
          </Button>
        </p>
        <p className="mt-4 text-gray-800 font-semibold">Request a demo today!</p>
      </div>
      <div className="lg:w-1/2">
        <HeroImage2 />
      </div>
    </div>
  </section>
)
