export default [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Jane Doe',
    customerTitle: 'Treasurer',
    customerImage: 'https://placeimg.com/48/48/people?1',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'John Doe',
    customerTitle: 'Chief Financial Officer',
    customerImage: 'https://placeimg.com/48/48/people?2',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Jane Smith',
    customerTitle: 'Chief Executive Officer',
    customerImage: 'https://placeimg.com/48/48/people?3',
  },
]
