import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "exposure.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <>
      <Img fluid={data.file.childImageSharp.fluid} alt="FX Exposure" objectFit="scale-down" />
    </>
  )
}

export default HeroImage
