import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 1130 868.1">
    <defs>
      <linearGradient
        id="a"
        x1="340.5"
        x2="340.5"
        y1="874.7"
        y2="452.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity=".3" />
        <stop offset=".5" stopColor="gray" stopOpacity=".1" />
        <stop offset="1" stopColor="gray" stopOpacity=".1" />
      </linearGradient>
    </defs>
    <path
      fill="#63b3ed"
      d="M1108 198a231 231 0 00-45-61l-622-5 512-61a337 337 0 00-103-17C800 20 737 0 668 0c-62 0-119 16-166 44-40-17-85-27-133-27-139 0-254 81-279 188zM1130 288a197 197 0 00-19-85L33 298a210 210 0 00-25 54l333 42L0 425c10 121 133 217 284 217 76 0 144-24 195-64 52 41 121 66 198 66 113 0 211-55 257-134 91-25 163-86 187-164l-422-55h431v-3z"
      opacity=".1"
    />
    <ellipse cx={500} cy="855.1" fill="#63b3ed" opacity=".1" rx={290} ry="12.9" />
    <path fill="#c8cad7" d="M672 832v8H441v-6a76 76 0 005-141h225a76 76 0 001 139z" />
    <path d="M671 693a76 76 0 00-42 51H488a76 76 0 00-42-51z" opacity=".1" />
    <path fill="#c8cad7" d="M176 654v44c0 24 18 43 40 43h680c22 0 40-19 40-43v-44z" />
    <path d="M672 832v8H441v-6a76 76 0 0018-10h199a76 76 0 0014 8z" opacity=".1" />
    <rect width="384.1" height="23.2" x="364.4" y="826.8" fill="#c8cad7" rx="9.5" ry="9.5" />
    <path fill="#474157" d="M936 173a40 40 0 00-40-40H216a40 40 0 00-40 40v489h760z" />
    <path
      fill="#4c4c78"
      d="M915 190v421a20 20 0 01-20 19H216a20 20 0 01-19-19V190a20 20 0 0119-19h679a20 20 0 0120 19z"
    />
    <circle cx="555.9" cy="151.9" r="9.2" fill="#fff" />
    <circle cx="555.9" cy="699.4" r="22.6" fill="#fff" />
    <path fill="#fff" d="M349 181v449H216a20 20 0 01-19-19V188l2-7z" />
    <path fill="#4c4c78" d="M366 188v40H197v-40h169z" />
    <path fill="#c8cad7" d="M915 190H197a20 20 0 0119-19h679a20 20 0 0120 19z" />
    <circle cx="216.3" cy="180.6" r="4.9" fill="#ededf4" />
    <circle cx="230.9" cy="180.6" r="4.9" fill="#ededf4" />
    <circle cx="245.5" cy="180.6" r="4.9" fill="#ededf4" />
    <path fill="#63b3ed" d="M222 206h101v9H222z" />
    <path
      fill="#63b3ed"
      d="M222 251h101v9H222zM222 283h101v9H222zM222 315h101v10H222zM222 348h101v9H222zM222 380h101v9H222zM222 412h101v9H222zM222 444h101v9H222z"
      opacity=".5"
    />
    <path
      fill="#dce0ed"
      d="M432 240h449v1H432zM432 275h449v1H432zM432 310h449v1H432zM432 344h449v2H432zM432 379h449v2H432z"
      opacity=".5"
    />
    <path
      fill="#63b3ed"
      d="M881 381H431v-69l41 20 43 6 37-11 45-35 23-13 17-3h16l26 13 35 23 28 4 22-8 47-38 24-9 25 4 21 12v104z"
      opacity=".5"
    />
    <path
      fill="#63b3ed"
      d="M509 338c-40 0-78-25-78-25l1-2c1 1 58 39 107 20 18-7 31-18 43-28 13-11 25-22 41-26 19-5 40-1 56 12 13 11 40 29 68 24 15-3 27-13 40-25 24-21 50-43 94-11l-1 1c-43-30-68-9-92 12-13 11-25 22-40 25-29 5-57-13-70-24a60 60 0 00-54-12c-16 4-28 14-41 25-12 11-25 22-43 29a84 84 0 01-31 5z"
    />
    <circle cx="647.5" cy="275.4" r="11.1" fill="#63b3ed" />
    <path
      fill="url(#a)"
      d="M404 533l-21-11h-1a13 13 0 01-4-5v-1c1-4 4-9 7-12l8-10a9 9 0 002-3 7 7 0 000-2v-1-7c0-2 0-4-2-5l-3-1a2 2 0 01-1-1 28 28 0 001-5 7 7 0 000-2c-1-3-5-5-9-5s-8-2-10-4a33 33 0 00-2-3 8 8 0 00-4-2 21 21 0 00-13 1l-5 2-6-2c-3 0-6 2-8 5a11 11 0 00-1 5 8 8 0 002 6l4 4v3a24 24 0 00-5 14 24 24 0 0017 23v1a10 10 0 01-3 8l-22 9s-13-2-17 17a32 32 0 00-1 10c0 3 0 8-4 14-5 11-4 35 7 37 10 2 14 1 15 1v1c0 4-2 43-8 48-2 1-1 2 0 2v1c0 3-1 14-5 20-5 7-4 48-4 48l-3 24s2 15 0 18c-2 2-4 57-5 69a6 6 0 00-5 3c-2 4-11 12-11 12-2 3-9 5-13 7a8 8 0 00-1 0 8 8 0 00-4 4 4 4 0 000 2c1 3 21 4 26 2 4-2 20-4 27-5a5 5 0 004-5 3 3 0 000-2c-2-2-2-6-2-9a6 6 0 001-1 63 63 0 014-25c5-13 6-37 5-52 0 0 15-64 26-70 0 1 6 8 7 30 0 23 9 38 9 38s-4 39 0 53l-5 23a7 7 0 01-1-1l1 2v1l1 10-1 7c-1 3-1 6 1 7 3 1 21 3 24-1a6 6 0 001-6 6 6 0 00-1-1s-5-8-4-13a4 4 0 000-3 161 161 0 014-26c3-11 9-36 5-53l-1-2a71 71 0 01-3-28 56 56 0 010-24l5-26s2-18 1-28l-1-1h1l-5-30-2-10a26 26 0 004-1c13-4 18-45 18-45v-13s4-20-11-29zm-98 315z"
      transform="translate(-35 -16)"
    />
    <path
      fill="#65617d"
      d="M361 753c5 16-1 42-4 54a166 166 0 00-4 26 55 55 0 01-21 0l5-25c-4-14 0-53 0-53s-8-14-9-37c-1-22-6-30-6-30-12 6-26 69-26 69 1 16 0 39-5 52a64 64 0 00-4 25c-1 2-4 2-6 2-7-1-15-7-15-7s3-69 5-72 0-17 0-17l3-24s-1-41 4-48c4-7 5-17 5-20v-1l7-8s66-5 71 0l2 7v29l-5 26a57 57 0 000 24 72 72 0 003 28z"
    />
    <path
      fill="#a27772"
      d="M357 856c-3 4-21 3-24 1-2 0-1-4-1-7l1-6c1-5-1-13-1-13s4 4 4 0a5 5 0 014-4 9 9 0 016 1c3 1 8 4 7 8-1 5 4 13 4 13a6 6 0 010 1 6 6 0 010 6z"
    />
    <path
      d="M357 856c-3 4-21 3-24 1-2 0-1-4-1-7l10 1a29 29 0 014 0 85 85 0 0011-1 6 6 0 010 6zM296 757c1 16 0 39-5 52a64 64 0 00-4 25c-1 2-4 2-6 2 1-10 8-53 11-87 5-39 26-65 26-65l4 4c-12 6-26 69-26 69z"
      opacity=".1"
    />
    <path
      fill="#fbbebe"
      d="M350 509l-3 14-15 24-17-8s-24-24-9-28a13 13 0 005-2c3-2 4-6 4-9a26 26 0 00-2-10l30-9a49 49 0 00-2 12c0 8 3 12 5 14a9 9 0 004 2z"
    />
    <path d="M343 481a49 49 0 00-2 12 24 24 0 01-26 7 26 26 0 00-2-10z" opacity=".1" />
    <path fill="#fbbebe" d="M347 477a24 24 0 11-24-24 24 24 0 0124 24z" />
    <path
      d="M350 509l-3 14-15 24-17-8s-24-24-9-28a13 13 0 005-2c-3 7 9 23 9 23 1 2 4 0 4 0 7-1 20-21 22-25a9 9 0 004 2zM363 646c-4 1-27 7-36 5l-29-2s-12 1-15-1v-1l7-8s66-5 71 0l2 7z"
      opacity=".1"
    />
    <path
      fill="#ff748e"
      d="M320 532s-13-17-8-24l-21 9s-13-2-17 17a33 33 0 00-1 10c0 3 0 8-4 14-5 11-4 35 7 37s14 1 14 1-1 43-7 48 15 4 15 4l29 3c10 2 37-5 37-5l-6-30-4-36 24-33s4-20-11-29l-20-11s-15 25-23 26c0 0-3 1-4-1z"
    />
    <path d="M292 539s0 8 1 11l7 20h-12s6-7 6-10l-2-21z" opacity=".1" />
    <path fill="#fbbebe" d="M327 598s-26-6-29-12l-9-17 3-4 13 10s15 12 22 13 0 10 0 10z" />
    <path d="M327 598s-26-6-29-12l-9-17 3-4 13 10s15 12 22 13 0 10 0 10z" opacity=".1" />
    <path
      fill="#fbbebe"
      d="M350 567v14h-3a66 66 0 00-32 9l-4 2-1 1-8-17 7-6h13c4-1 6-4 15-2 4 1 8 6 12 4z"
    />
    <path
      d="M372 542l5 5v13s-4 40-17 45-35-3-35-3-6-15-3-16l24-7s0-24 4-31c0 0-3-22 8-23s14 17 14 17z"
      opacity=".1"
    />
    <path
      fill="#ff748e"
      d="M373 542l5 5v13s-4 40-17 45-36-3-36-3-5-15-3-16l25-7s-1-24 4-31c0 0-3-22 8-23s14 17 14 17z"
    />
    <path
      d="M340 487a9 9 0 00-1-5 4 4 0 00-3-2l-5 4-4-2a11 11 0 01-1-5v-6c0-3 0-5-2-7-1-2-5-3-8-2a7 7 0 00-4 1 6 6 0 01-5 2 4 4 0 01-4-3 24 24 0 1136 32 67 67 0 001-7z"
      opacity=".1"
    />
    <path
      fill="#7c5c5c"
      d="M307 464a4 4 0 01-4-3v-2l-4-4c-3-3-2-8-1-11 2-3 5-5 8-4l6 1 5-1a20 20 0 0113-2 8 8 0 013 2 31 31 0 012 3c3 3 7 3 10 4s8 2 9 5l-1 7a2 2 0 000 2l3 1c2 0 3 3 3 5v7a9 9 0 010 2 9 9 0 01-2 3l-8 10c-3 4-7 9-6 14-2 1-4 0-4-2v-6a65 65 0 001-9 9 9 0 000-4 4 4 0 00-4-3c-2 1-3 4-5 4-2 1-4 0-4-2a11 11 0 01-1-5v-6c0-2 0-5-2-6-1-2-5-3-8-3a7 7 0 00-4 2 6 6 0 01-5 1z"
    />
    <path
      d="M312 539l8 30s5 0-8-30zM337 534s-9 15-5 32h1s-3-8 4-32zM344 623s11-4 12 6c0 0-2-7-12-6zM309 571s-2 16 2 23a52 52 0 01-21 3l-1-26z"
      opacity=".1"
    />
    <path d="M311 592l-1 1-8-17 7-6h1c-1 1-2 15 1 22z" opacity=".1" />
    <path fill="#ff748e" d="M309 570s-2 17 2 23a52 52 0 01-21 3l-8-26z" />
    <path
      fill="#a27772"
      d="M289 846a5 5 0 01-5 5c-6 1-22 3-26 5-5 3-24 1-25-1a4 4 0 010-3 8 8 0 015-4c3-2 10-4 12-7l12-12a6 6 0 014-2c2-1 4 0 5 5 0 0 12 6 15 1 0 0 0 9 2 11a3 3 0 011 2z"
    />
    <path
      d="M289 846a5 5 0 01-5 5c-6 1-22 3-26 5-5 3-24 1-25-1a4 4 0 010-3 8 8 0 013-4c4 2 11 4 26 2s23-5 26-6a3 3 0 011 2z"
      opacity=".1"
    />
    <g opacity=".1">
      <path d="M353 460a22 22 0 001-6 34 34 0 01-1 5 3 3 0 000 1zM339 482a7 7 0 011 3 8 8 0 00-1-4 4 4 0 00-3-2c-2 0-3 3-5 3-2 1-4 0-4-2a11 11 0 01-1-5v-6c0-2 0-4-2-6-1-2-5-3-8-3a7 7 0 00-4 2 6 6 0 01-5 2 4 4 0 01-4-3v-3l-4-4a8 8 0 01-2-5 8 8 0 002 6l4 4v3a4 4 0 004 3 6 6 0 005-2 7 7 0 014-2c3 0 7 1 8 3 2 2 2 4 2 6v6a11 11 0 001 5c0 2 2 3 4 2 2 0 3-3 5-3a4 4 0 013 2zM359 475a9 9 0 01-2 4l-8 9c-3 4-7 8-6 14 0-5 3-9 6-13l8-9a9 9 0 002-4 9 9 0 000-2 6 6 0 010 1zM339 500a6 6 0 01-1-1 6 6 0 001 2c0 2 2 3 4 2v-1c-2 1-4 0-4-2z" />
    </g>
    <path
      d="M260 831s2 5 11 1M336 833s7 9 17 3M358 725s-5 3-6 5 3-1 3-1zM360 751a11 11 0 01-6 3c-5 0-4 5-2 4l8-7zM361 755s-2 12-4 10"
      opacity=".1"
    />
    <g fill="#dce0ed" opacity=".5">
      <path d="M471 459h66v14h-66zM623 459h66v14h-66zM774 459h66v14h-66z" />
    </g>
    <ellipse cx="952.7" cy="801.3" fill="#63b3ed" opacity=".1" rx="26.9" ry="4.5" />
    <ellipse cx="115.2" cy="858.7" fill="#63b3ed" opacity=".1" rx="26.9" ry="4.5" />
    <path
      fill="#63b3ed"
      d="M910 839a12 12 0 004-6c1-2 0-5-2-6-3-1-6 1-8 3s-4 3-6 3a11 11 0 003-10 4 4 0 00-1-2c-1-1-4-1-6 1-5 3-6 10-6 17v-7c0-2-1-5-3-7a8 8 0 00-4-1l-6 2c-2 2-2 6 0 8l7 6a15 15 0 014 4 5 5 0 011 1h14a41 41 0 009-6zM116 791s6 7-2 18-15 20-12 27c0 0 12-20 22-20s3-13-8-25z"
    />
    <path
      d="M116 791a9 9 0 012 3c9 11 14 21 5 22-9 0-19 15-21 19a9 9 0 000 1s12-20 22-20 3-13-8-25z"
      opacity=".1"
    />
    <path
      fill="#ffd037"
      d="M127 800l-1 5-1-5c0-3 1-1 1-1l1 1zM130 803l-4 2 4-3c3-2 1 0 1 0l-1 1z"
    />
    <path fill="#63b3ed" d="M87 791s-5 7 3 18 15 20 12 27c0 0-12-20-22-20s-3-13 7-25z" />
    <path
      d="M87 791a9 9 0 00-1 3c-9 11-14 21-5 22 8 0 19 15 21 19a7 7 0 010 1s-12-20-22-20-3-13 7-25z"
      opacity=".1"
    />
    <path fill="#ffd037" d="M77 800l1 5v-5-1s-1-1-1 1zM74 803l4 2-4-3c-3-2-1 0-1 0l1 1z" />
    <path fill="#a8a8a8" d="M79 835s15 0 20-4 24-7 25-2 23 27 6 27-41-3-45-6-6-15-6-15z" />
    <path
      d="M130 854c-18 0-41-3-45-6-4-2-5-9-6-13s1 12 6 15 27 6 44 6c5 0 7-2 7-5-1 2-2 3-6 3z"
      opacity=".2"
    />
    <path
      fill="#63b3ed"
      d="M467 556c0-20 16-36 37-36v-9c-26 0-47 20-47 45a46 46 0 0016 35l7-6a37 37 0 01-14-29z"
    />
    <path
      fill="#63b3ed"
      d="M505 511v9a37 37 0 11-24 66l-7 6a46 46 0 1031-81zM809 520c20 0 36 16 36 37h9c0-26-20-46-45-46a46 46 0 00-35 15l6 7a37 37 0 0129-13z"
      opacity=".5"
    />
    <path fill="#63b3ed" d="M854 558h-9a37 37 0 11-65-24l-7-7a46 46 0 1081 31z" />
  </svg>
)
