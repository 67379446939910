import React from 'react'
import Button from '../components/Button'
import CustomerCard from '../components/CustomerCard'
import LabelText from '../components/LabelText'
import Layout from '../components/layout/Layout'
import SplitSection from '../components/SplitSection'
import StatsBox from '../components/StatsBox'
import customerData from '../data/customer-data'
import HeroImage from '../svg/HeroImage'
import HeroComponent from '../components/HeroComponent'
import SvgCharts from '../svg/SvgCharts'
import Pricing from '../components/Pricing'
import RealTimeSync from '../svg/RealTimeSync'
import Features from '../components/Features'
import Investing from '../svg/Investing'
import AllTheData from '../svg/AllTheData'
import Dashboard from '../svg/Dashboard'
import ConnectedWorld from '../svg/ConnectedWorld'

export default () => (
  <Layout>
    <HeroComponent />
    <section id="features" className="py-20 mt-10 sm:mt-20">
      <div className="container mx-auto text-center">
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight"> Global Treasury Solutions </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                FinStar empowers CFOs and treasurers with the tools and reporting they need to
                optimize cash and liquidity, control bank accounts, deliver compliance and better
                manage in-house banking and financial transactions.
              </p>
            </div>
          }
          secondarySlot={<Dashboard />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">SaaS and Cloud Based</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Hosted in a Software-as-a-Service private cloud environment, FinStar provides an
                whole solution to your treasury needs. Predictable pricing, based on the modules you
                need. No up-front licence fees, quick setup, deployment and automatic updates.
              </p>
            </div>
          }
          secondarySlot={<ConnectedWorld />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Financial Transactions</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                FinStar supports the management of Debt & Investments including intercompany loans
                to enable you to manage the full transaction lifecycle, from the deal
                capture/import, interest calculations, accounting, valuations, and reporting.
              </p>
            </div>
          }
          secondarySlot={<HeroImage />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">Financial Risk Management</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Manage exposures holistically and protect your company from increasing volatility
                and uncertainty.
              </p>
            </div>
          }
          secondarySlot={<AllTheData />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">FX Exposure Management</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Analyze compliance with your internal hedging policies by tracking of currency
                exposures and FX transactions. The FX Exposure report provides a quick and intuitive
                way to visualize the FX exposures and executed transactions.
              </p>
            </div>
          }
          secondarySlot={<Investing />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Foreign Exchange Transactions
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                FinStar supports working with many transaction types, including spot, forward, NDF,
                Swaps, plain vanilla and option transactions.
              </p>
            </div>
          }
          secondarySlot={<SvgCharts />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Use it in any device</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                FinStar provides excellent usability and allows you to log in from any device, such
                as a mobile phone, tablet, or laptop.
              </p>
            </div>
          }
          secondarySlot={<RealTimeSync />}
        />
      </div>
    </section>
    <section id="additional-features" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold ">Additional Features</h2>
        <Features />
      </div>
    </section>
    <section id="pricing" className="py-10 lg:pb-20">
      <Pricing />
    </section>
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-10 lg:py-20">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">
          What our customers are saying
        </LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to modernize your business?</h3>
      <p className="mt-8 text-xl font-light">
        Embrace the digital transformation of your business and try FinStar today!
      </p>
      <p className="mt-8">
        <Button size="xl" to="/demo">
          Get Started Now
        </Button>
      </p>
    </section>
  </Layout>
)
